import { RecommendationLevels } from '../enums/RecommendationLevels'
import { SkillItemProgress } from '../types/Session'
import { Images } from './Images'
import {
  helpingAbilitySharedWeight,
  helpingCoordinatingWithOthersWeight,
  helpingResourcesSharedWeight
} from './SessionInfoConstants'
import { helpingAbilityShared, helpingCoordinatingWithOthers, helpingResourcesShared } from './SessionInfoHelper'
import { SkillDevelopmentItemInfo } from './SkillDevelopmentItemInfo'
import { Texts } from './Texts'
import { toPercent } from './getSessionInfo'

export enum SkillDevelopmentItemHelping {
  ResourcesShared = 'ResourcesShared',
  AbilitiesShared = 'AbilitiesShared',
  CoordinatingWithOthers = 'CoordinatingWithOthers'
}

export const SkillDevelopmentItemInfoHelping: {
  [property in SkillDevelopmentItemHelping]: SkillDevelopmentItemInfo
} = {
  [SkillDevelopmentItemHelping.ResourcesShared]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleResourceItemsShared,
    imageKey: Images.imgResourcesSharedWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoResourceItemsShared,
    getValueForSlotDataPoint: ({ dataPoint }) => helpingResourcesShared(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.timesSharedResourcesToOthers / slots.length, // TODO, should also account for timesSharedItems see IN-XX
    formatDisplayValue: (n) => n.toFixed(0),
    weight: helpingResourcesSharedWeight,
    calculatedBy: [
      {
        key: 'timesSharedResourcesToOthers',
        weight: 0.5,
        getValueForSlotDataPoint: helpingResourcesShared
      },
      {
        key: 'timesSharedItemsToOthers',
        weight: 0.5,
        getValueForSlotDataPoint: () => 0
      }
    ],
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: undefined,
      [RecommendationLevels.slightlyHigh]: undefined,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecHaRecHaRaisSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecHaRecHaRaisTooLow
    },
    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue < 4:
          return RecommendationLevels.tooLow
        case userDisplayValue < 20:
          return RecommendationLevels.slightlyLow
        default:
          return undefined
      }
    }
  },
  [SkillDevelopmentItemHelping.AbilitiesShared]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleAbilitiesShared,
    imageKey: Images.imgAbilitiesSharedWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoAbilitiesShared,
    getValueForSlotDataPoint: ({ dataPoint }) => helpingAbilityShared(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.timesSharedAbilityToOthers / slots.length,
    formatDisplayValue: (n) => n.toFixed(0),
    weight: helpingAbilitySharedWeight,
    metaDataPointKey: 'timesSharedAbilityToOthers',
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: undefined,
      [RecommendationLevels.slightlyHigh]: undefined,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecHaRecHaTsatoSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecHaRecHaTsatoTooLow
    },

    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue < 4:
          return RecommendationLevels.tooLow
        case userDisplayValue < 25:
          return RecommendationLevels.slightlyLow
        default:
          return undefined
      }
    }
  },
  [SkillDevelopmentItemHelping.CoordinatingWithOthers]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleCoordinatingOthers,
    imageKey: Images.imgCommunicationRecieptWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoCoordinatingOthers,
    getValueForSlotDataPoint: ({ dataPoint }) => helpingCoordinatingWithOthers(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.timeAtMapPercentage / slots.length,
    formatDisplayValue: (n) => `${toPercent(n)} %`,
    weight: helpingCoordinatingWithOthersWeight,
    enableAlways100Percent: true,
    metaDataPointKey: 'timeAtMapPercentage',
    enableYourScoreRatio: false,
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: Texts.dynamicTxtRecHaRecHaTampTooHigh,
      [RecommendationLevels.slightlyHigh]: Texts.dynamicTxtRecHaRecHaTampSlightlyHigh,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecHaRecHaTampSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecHaRecHaTampTooLow
    },
    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue < 0.05:
          return RecommendationLevels.tooLow
        case userDisplayValue < 0.1:
          return RecommendationLevels.slightlyLow
        case userDisplayValue < 0.2:
          return RecommendationLevels.slightlyHigh
        case userDisplayValue < 0.3:
          return RecommendationLevels.tooHigh
        default:
          return undefined
      }
    }
  }
}
