import { OnboardingChapter } from '../../../pure-js/enums/OnboardingChapter'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { OnboardingChapterItemAssessment } from '../components/OnboardingChapterItemAssessment'
import { Texts } from '../../../pure-js/libs/Texts'

const OnboardingChapterArray = Object.values(OnboardingChapter)
export const LastOnboardingChapter = OnboardingChapterArray[OnboardingChapterArray.length - 1]
export const FirstOnboardingChapter = OnboardingChapterArray[0]

export type OnboardingChapterInfoItemProps = {
  chapter: OnboardingChapter
}

export type OnboardingChapterInfoItem = {
  headerTextKey: TextKey
  descriptionTextKeys?: TextKey[]
  videoUrl?: string
  buttonTextKey?: TextKey
  onClickCompleteSuccess?: () => Promise<unknown>
  onboardingStep?: number
  Component?: React.FC<OnboardingChapterInfoItemProps>
  enabled?: boolean
}

export const OnboardingChapterInfoItems: {
  [property in OnboardingChapter]: OnboardingChapterInfoItem
} = {
  [OnboardingChapter.Introduction]: {
    headerTextKey: Texts.onboardingOnbardingPageOnboardingIntroductionHeaderText,
    descriptionTextKeys: [
      Texts.onboardingOnbardingPageOnboardingIntroductionText1,
      Texts.onboardingOnbardingPageOnboardingIntroductionText13
    ],
    videoUrl:
      'https://storage.googleapis.com/intraction-f177a.appspot.com/transition-web/assets/Intraction_Introduction.mp4',
    buttonTextKey: Texts.onboardingOnbardingPageCtaCompletedIntroduction
  },
  [OnboardingChapter.Assessment]: {
    headerTextKey: Texts.onboardingOnbardingPageOnboardingAssessmentHeaderText,
    buttonTextKey: Texts.onboardingAsessmentQuestionsCtaLastQuestion,
    Component: OnboardingChapterItemAssessment
  },
  [OnboardingChapter.GameIntroduction]: {
    headerTextKey: Texts.onboardingOnbardingPageGameIntroduction,
    videoUrl: 'https://storage.googleapis.com/intraction-f177a.appspot.com/transition-web/assets/Intraction_Story.mp4',
    buttonTextKey: Texts.onboardingOnbardingPageCtaStartGameIntroduction,
    onClickCompleteSuccess: async () => (window.location.href = RoutePath.PLAY_GAME_WITH_INTRODUCTION)
  }
}

export const getIndexOfOnboadingChapter = (c?: OnboardingChapter): number =>
  OnboardingChapterArray.findIndex((chapter) => chapter === c)

export const getNextOnboardingChapter = (c?: OnboardingChapter): OnboardingChapter => {
  if (c === LastOnboardingChapter) return LastOnboardingChapter

  return OnboardingChapterArray[getIndexOfOnboadingChapter(c) + 1]
}
