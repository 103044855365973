import invariant from 'invariant'
import { dayjs } from '../../transition-web/src/libs/DayJsHelper'
import { Slot } from '../types/Slot'
import { ONE_SECOND } from './Consts'

/*
Average total slot time (ATST)

Average user inactive time (ITI+ITO) = (UIT)

Average inactive team time = SUM(all users UIT)

Team Inactive Time Ratio = SUM(all users UIT) / ATST

User Percentage = UIT/ SUM(all users UIT)
*/

export function getTeamInactiveTimeRatio(p: Props) {
  const { slots } = p
  const userIds = slots.flatMap((s) => s.userIds).length / slots.length
  console.log('userIds', userIds)
  const totalSlotTime = getTotalSlotTime(p.slots)
  console.log('totalSlotTime', totalSlotTime / ONE_SECOND)

  const teamInactiveTime = getTeamTimeForSession({ slots })

  return teamInactiveTime / totalSlotTime / userIds / slots.length
}

export function getUserInactiveTimeRatio(p: Required<Props>) {
  const { uid, slots } = p
  invariant(uid, 'uid is required')

  const averageInactiveTeamTime = getTeamTimeForSession({ slots })
  const userInactiveTime = getInactiveUserTimeForSession({ ...p, uid })

  return userInactiveTime / averageInactiveTeamTime
}

function getTotalSlotTime(slots: Slot[]) {
  return slots.reduce((a, b) => a + (dayjs(b.actual_game_finish_time).valueOf() - dayjs(b.start_time).valueOf()), 0)
}

type Props = {
  slots: Slot[]
  uid?: string
}

export function getTeamTimeForSession(p: Omit<Props, 'uid'>) {
  return getInactiveTimeForSession(p)
}

export function getInactiveUserTimeForSession(p: Required<Props>) {
  return getInactiveTimeForSession(p)
}

function getInactiveTimeForSession({ slots, uid }: Props) {
  return slots.reduce(
    (a, b) =>
      a +
      b.slotDataPoints
        .filter((dp) => !uid || dp.uid === uid)
        .reduce((a, b) => {
          console.log(
            'uid',
            uid || 'team',
            'slot',
            b.id,
            '(b.inactiveTimeInside + b.inactiveTimeOutside)',
            (b.inactiveTimeInside + b.inactiveTimeOutside) / ONE_SECOND,
            'inactiveTimeInside',
            b.inactiveTimeInside / ONE_SECOND,
            'inactiveTimeOutside',
            b.inactiveTimeOutside / ONE_SECOND
          )
          return a + (b.inactiveTimeInside + b.inactiveTimeOutside)
        }, 0),
    0
  )
}
