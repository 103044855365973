import styled from '@emotion/styled'
import { White } from '../../../pure-js/libs/Colors'
import RoutePath from '../../../pure-js/libs/RoutePath'
import Box from '../components/Box'
import Button from '../components/Button'
import { Layout } from '../components/Layout'
import { PlayGame } from '../components/PlayGame'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import { Texts } from '../../../pure-js/libs/Texts'

export const PlayGamePage = () => {
  const navigate = useNavigate()

  const onClickIntroduction = () => navigate(RoutePath.PLAY_GAME_WITH_INTRODUCTION)
  const onClickEnterTeamLobby = () => navigate(RoutePath.PLAY_GAME_WITHOUT_INTRODUCTION)

  return (
    <Layout enableMenu>
      <Box align="center" justify="center" fullWidth style={{ paddingTop: SMALL_SPACING_PX * 20 }}>
        <Container fullPadding>
          <Box direction="row" align="center">
            <Box>
              <Button textKey={Texts.gameCenterStartOnboardingCta} onClick={onClickIntroduction} />
            </Box>
            <Box left>
              <Button textKey={Texts.gameCenterEnterLobbyCta} buttonType="secondary" onClick={onClickEnterTeamLobby} />
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${White};
  border-radius: ${BorderRadixes.moderate};
`

export const PlayGameWithoutIntroductionPage = () => {
  return (
    <Layout enableMenu>
      <PlayGame />
    </Layout>
  )
}

export const PlayGameWithIntroductionPage = () => {
  return (
    <Layout enableMenu>
      <PlayGame gameParams={{ introduction: true }} />
    </Layout>
  )
}
