import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import { getStorage } from 'firebase/storage'
import { FIREBASE_CONFIG, REGION } from '../../../pure-js/libs/Consts'

// Configure Firebase.
export const app = initializeApp(FIREBASE_CONFIG)

export const db = initializeFirestore(app, { ignoreUndefinedProperties: true })

export const auth = getAuth(app)

export const functions = getFunctions(app, REGION)

export const storage = getStorage(app)
