import { Slot, SlotDataPoint } from '../types/Slot'

export function getInactiveTimeForSessionForUser({
  slots,
  dataPoint,
  uid
}: {
  slots: Slot[]
  dataPoint?: SlotDataPoint
  uid?: string
}) {
  return slots.reduce(
    (a, b) =>
      a +
      b.slotDataPoints
        .filter((dp) => !uid || dp.uid === dataPoint?.uid)
        .reduce((a, b, i, arr) => a + (b.inactiveTimeInside + b.inactiveTimeOutside) / arr.length, 0),
    0
  )
}
