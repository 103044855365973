import { getSessionInfo } from '../../../pure-js/libs/getSessionInfo'
import { SessionInfo } from '../../../pure-js/types/Session'
import { useSessions } from './useSessions'
import { useSession } from './useSession'
import { useUser } from './useUser'
import config from '../Config'

export function useSessionInfo(sessionId: string): SessionInfo | undefined {
  const { data: user } = useUser()
  const sessionWithSlots = useSession(sessionId)
  const { data: sessionsWithSlots = [] } = useSessions()

  if (!sessionWithSlots) return undefined

  const sessionInfo = getSessionInfo(
    {
      session: sessionWithSlots,
      uid: user?.id,
      sessionsWithSlots
    },
    config
  )
  return sessionInfo
}
