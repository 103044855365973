/* eslint-disable camelcase */
import { SlotDataPoint } from '../types/Slot'
import { GetSessionInfoOptions } from './getSessionInfo'
import * as C from './SessionInfoConstants'

export const taskPerformanceUridiumCollected = ({ uridiumCollectedTotal }: SlotDataPoint): number =>
  uridiumCollectedTotal
export const taskPerformanceAbilityUsed = ({ timesUsedAbility }: SlotDataPoint): number => timesUsedAbility
export const taskPerformanceTimesCalledDrone = ({ timesSuccessfullyGotDrone }: SlotDataPoint): number =>
  timesSuccessfullyGotDrone
export const taskPerformanceHypothermia = ({ paralyzedEvents }: SlotDataPoint): number => paralyzedEvents?.length || 0
export const taskPerformancePeopleHealed = ({ timesSharedResourcesToOthers }: SlotDataPoint): number =>
  timesSharedResourcesToOthers || 0
export const taskPerformanceTimesUnlockedRobot = ({ timesUnlockedRobot }: SlotDataPoint): number => timesUnlockedRobot

// Excel formula: 1+(MIN(MIN(AT-AT_C1;AT_C2-AT);0))/AT_C3
export function communicatingAirTime({ airTime: AT }: SlotDataPoint): number {
  const { AT_C1, AT_C2, AT_C3 } = C
  return 1 + Math.min(Math.min(AT - AT_C1, AT_C2 - AT), 0) / AT_C3
}

// Excel formula: MIN(MAX(1+(MIN(ATR-ATR_C1;ATR_C2-ATR)/ATR_C3);0);1)
export function commiunicatingAirTimeRelative({ airTimeRelative: ATR }: SlotDataPoint): number {
  const { ATR_C1, ATR_C2, ATR_C3 } = C
  return Math.min(Math.max(1 + Math.min(ATR - ATR_C1, ATR_C2 - ATR) / ATR_C3, 0), 1)
}

// Excel formula: 1+(MIN(ABS(MIN(MIN(TT-TT_C1;(TT_C2-TT)*TT_C3);0)/TT_C4);1))*-1
export function communicatingTimesTalked({ timesTalked: TT }: SlotDataPoint): number {
  const { TT_C1, TT_C2, TT_C3, TT_C4 } = C
  return 1 + Math.min(Math.abs(Math.min(Math.min(TT - TT_C1, (TT_C2 - TT) * TT_C3), 0) / TT_C4), 1) * -1
}

// Excel formula: MIN(RCPT;RCPT_C1)/RCPT_C2
export function communicatingActiveListening({ timesReceipt: RCPT }: SlotDataPoint): number {
  const { RCPT_C1, RCPT_C2 } = C
  return Math.min(RCPT, RCPT_C1) / RCPT_C2
}

// Excel formula: 1-MIN(OLT;OLT_C1)/OLT_C2
export function communicatingInteruptingOthers({ timesOverlapAirTime: OLT }: SlotDataPoint): number {
  const { OLT_C1, OLT_C2 } = C
  return 1 - Math.min(OLT, OLT_C1) / OLT_C2
}

// Excel formula: (1-EXP(nCPLX_C1*C10-nCPLX_C2)-nCPLX_C3)/nCPLX_C4
export function communicatingHowYouFeel({ timesCircumplex: nCPLX }: SlotDataPoint): number {
  const { nCPLX_C1, nCPLX_C2, nCPLX_C3, nCPLX_C4 } = C
  return (1 - Math.exp(nCPLX_C1 * nCPLX - nCPLX_C2) - nCPLX_C3) / nCPLX_C4
}

// Excel formula: MIN(ShR;ShR_C1)/ShR_C2
export function helpingResourcesShared({ timesSharedResourcesToOthers: ShR }: SlotDataPoint): number {
  const { ShR_C1, ShR_C2 } = C
  return (Math.min(ShR, ShR_C1) / ShR_C2) * 0.5
}

// Currently not used
// Excel formula: MIN(ShI;ShI_C1)/ShI_C2
export function helpingItemsShared({ timesSharedResourcesToOthers: ShI }: SlotDataPoint): number {
  return 0
}

// Excel formula: MIN(ShA;ShA_C1)/ShA_C2
export function helpingAbilityShared({ timesSharedAbilityToOthers: ShA }: SlotDataPoint): number {
  const { ShA_C1, ShA_C2 } = C
  const res = Math.min(ShA, ShA_C1) / ShA_C2

  return res
}

// Excel formula: MIN(MAX(1+(MIN(TAMP-TAMP_C1; TAMP_C2 - TAMP)/TAMP_C3);0);1)
export function helpingCoordinatingWithOthers({ timeAtMapPercentage: TAMP }: SlotDataPoint): number {
  const { TAMP_C1, TAMP_C2, TAMP_C3 } = C
  return Math.min(Math.max(1 + Math.min(TAMP - TAMP_C1, TAMP_C2 - TAMP) / TAMP_C3, 0), 1)
}

export function decisionMakingRiskProfile(p: SlotDataPoint): number {
  const {
    decisionMakingRiskProfilePathSelectedScoreWeight: weightPathSelectedScore,
    decisionMakingRiskProfilePathInitiatedTogetherSummariesWeight: weightPathInitiatedTogetherSummaries
  } = C
  const res =
    pathSelectedScore(p) * weightPathSelectedScore +
    pathInitiatedTogetherSummaries(p) * weightPathInitiatedTogetherSummaries

  return res
}

// Excel formula: 1-(IF(AND(PS >= MIN(PS_C1;PS_C2);PS <= MAX(PS_C1;PS_C2)); 0; MIN(ABS(PS - PS_C3); ABS(PS - PS_C4))))/PS_C5
export function pathSelectedScore({ pathSelectedScore: PS }: SlotDataPoint): number {
  const { PS_C1, PS_C2, PS_C3, PS_C4, PS_C5 } = C

  if (PS >= Math.min(PS_C1, PS_C2) && PS <= Math.max(PS_C1, PS_C2)) return 1
  else return 1 - Math.min(Math.abs(PS - PS_C3), Math.abs(PS - PS_C4)) / PS_C5
}

// Excel formula: PITS_C3+(MIN(ABS(MIN(MIN(PITS-1;(PITCS_C1-PITS)*PITS_C2);0)/PITS_3);1))*-1
export function pathInitiatedTogetherSummaries({ pathInitiatedTogetherSummaries }: SlotDataPoint): number {
  const PITS = pathInitiatedTogetherSummaries?.length
  const { PITS_C1, PITS_C2, PITS_C3 } = C
  return PITS_C3 + Math.min(Math.abs(Math.min(Math.min(PITS - 1, (PITS_C1 - PITS) * PITS_C2), 0) / PITS_C3), 1) * -1
}

export function decisionMakingPrioritizedDecisionMaking(p: SlotDataPoint, config?): number {
  const {
    decisionMakingPrioritizedDecisionMakingTimeParalyzedWeight: weightTimeParalyzed,
    decisionMakingPrioritizedDecisionMakingNumberOfTanksFullWhenReturningWeight: weightNumberOfTanksFullWhenReturning,
    decisionMakingPrioritizedDecisionMakingRobotFailRateAtMapWeight: weightUnlockRobotFailRateAtMap,
    decisionMakingPrioritizedDecisionMakingRobotFailRateWeight: weightUnlockRobotFailRate
  } = C

  return (
    timeParalyzed(p, config) * weightTimeParalyzed +
    numberOfTanksFullWhenReturning(p) * weightNumberOfTanksFullWhenReturning +
    unlockRobotFailRate(p) * weightUnlockRobotFailRate +
    unlockRobotFailRateAtMap(p) * weightUnlockRobotFailRateAtMap
  )
}

// Excel formula: 1-(min(TP;TP_C1)/TP_C2)
export function timeParalyzed(dp: SlotDataPoint, opts: GetSessionInfoOptions): number {
  const { timeParalyzed: TP } = dp
  if (opts?.enableTimeParalyzedExperimental) return timeParalyzedExperimental(dp)
  const { TP_C1, TP_C2 } = C
  return 1 - Math.min(TP, TP_C1) / TP_C2
}

export function timeParalyzedExperimental({ timeParalyzedExperimental: TP }: SlotDataPoint): number {
  const { TP_C1, TP_C2 } = C
  return 1 - Math.min(TP || 0, TP_C1) / TP_C2
}

// Excel formula: 1+(MIN(ABS(MIN(MIN(nTFWR-nTFWR_C1;(nTFWR_C2-nTFWR)*nTFWR_C3);0)/nTFWR_C4);1))*-1
export function numberOfTanksFullWhenReturning({
  totalNumberOfTanksFilledWhenReturning: nTFWR
}: SlotDataPoint): number {
  const { nTFWR_C1, nTFWR_C2, nTFWR_C3, nTFWR_C4 } = C

  return (
    1 + Math.min(Math.abs(Math.min(Math.min(nTFWR - nTFWR_C1, (nTFWR_C2 - nTFWR) * nTFWR_C3), 0) / nTFWR_C4), 1) * -1
  )
}

// Excel formula: IRFR_C1-URFR
export function unlockRobotFailRate({ unlockRobotFailRate: URFR }: SlotDataPoint): number {
  if (isNaN(URFR)) URFR = 0
  const { URFR_C1 } = C
  return URFR_C1 - URFR
}

// Excel formula: IRFR_C1-URFR
export function unlockRobotFailRateAtMap({ unlockRobotFailRateAtMap: URFRAM }: SlotDataPoint): number {
  if (isNaN(URFRAM)) URFRAM = 0
  const { URFRAM_C1 } = C
  return URFRAM_C1 - URFRAM
}

export function decisionMakingTimeManagement(p: SlotDataPoint): number {
  const {
    decisionMakingTimeManagementInactiveTimeInside: weightInactiveTimeInside,
    decisionMakingTimeManagementInactiveTimeOutsideWeight: weightInactiveTimeOutside
  } = C
  return inactiveTimeInside(p) * weightInactiveTimeInside + inactiveTimeOutside(p) * weightInactiveTimeOutside
}

// Excel formula: 1-(min(ITI;ITI_C1)/ITI_C2)
export function inactiveTimeInside({ inactiveTimeInside: ITI }: SlotDataPoint): number {
  const { ITI_C1, ITI_C2 } = C
  return 1 - Math.min(ITI, ITI_C1) / ITI_C2
}

// Excel formula: 1-(min(ITO;ITO_C1)/ITO_C2)
export function inactiveTimeOutside({ inactiveTimeOutside: ITO }: SlotDataPoint): number {
  const { ITO_C1, ITO_C2 } = C
  return 1 - Math.min(ITO, ITO_C1) / ITO_C2
}
